.Contact {
	
}

.Contact-title-header {
	display: flex;
	flex-direction: column;
	margin: 0 1em;
}

.Contact-title-item {
	align-self: start;
	font-family: 'Recursive', sans-serif;
	font-size: 2em;
	font-weight: bold;
	color: #2D2D2A;
}

.Contact-title-hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: solid #FFDD30 4px;
	height: 2px;
    box-shadow: inset 0 12px 12px -12px #2D2D2A;
}


.Contact-message-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px;
}

.spinner-size {
	width: 5em; 
	height: 5em;
}

.Contact-form {
	margin: 1em;
}

.Contact-form-row {
	display: flex;
	flex-direction: column;
}




@media(min-width: 768px){
	.Contact-form-row{
		flex-direction: row;
		justify-content: center;
	}
	
	.Contact-form-row-item {
		margin-right: 10px;
	}
	
	.Contact-form-textarea {
		width: 600px;
	}
	
	.Contact-textarea-container{
		display: flex;
		justify-content: center;
	}
	
	.Contact-form-button-container{
		display: flex;
		justify-content: center;
	}
	
	.Contact-form-submit{
		width: 600px;
	}
	
	
}