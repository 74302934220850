.Navbar-bg {
    background: linear-gradient(133deg, rgba(45,45,42,1) 39%, rgba(74,74,73,1) 75%, rgba(45,45,42,1) 100%);;
}

.Navbar-logo {
	font-family: 'Recursive', sans-serif;
    margin: 0 5em;
	margin-bottom: 5px;
}

.Navbar-menu-space {
    width: 10em;
}

.Navbar-logo-some {
    font-size: 3.5vw;
    color: #FFDD30;
    font-weight: bold;
}

.Navbar-logo-coder {
    font-size: 3.5vw;
    color: #FFFFFF;
    font-weight: bold;
}

.Navbar-buttons {
	font-family: 'Recursive', sans-serif;
    width: 10em;
    font-size: 14px;
	margin-right: 5px;
}

.Navbar-links {
    display: none;
}

.Navbar-hamburger-toggler {
    width: 20px;
    height: 10px;
	color: #FFDD30;
    font-size: 2em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Navbar-close-toggler-spin {
	position: relative;
	font-size: 2em;
    width: 40px;
    height: 40px;
	color: #2D2D2A;
    display: flex;
	align-items: center;
	justify-content: center;
	animation: spin-animation 1s 1;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.Navbar-popup-menu {
    visibility: visible;
    z-index: 1050;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	transform-origin: top right;
	transform: scale(1);
	transition-duration: .25s;
    background: #FFDD30; 
}

.Navbar-popup-menu-off {
   
	transform: scale(0);
	transition: all .25s ease;
}

.Navbar-links-popup-menu {
    height: 80vh;
}

.Navbar-menu-links-popup {
	font-family: 'Recursive', sans-serif;
	font-size: 3em;
	font-weight: bold;
    color: #2D2D2A !important;
    cursor: pointer;
}

.Navbar-menu-links {
	
    color: #FFDD30 !important;
	margin: 0 1em;
	font-weight: bold;
    cursor: pointer;
}

.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}

.headroom--pinned {
    transform: rotateX(0deg);
}

.headroom--unpinned {
    transform: rotateX(90deg);
}

.Navbar-to-top {
    background: white;
    position: fixed;
    bottom: 16px;
    right:32px;
    width:50px;
    height:50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size:32px;
    color:#1f1f1f;
    text-decoration: none;
    opacity:0;
    pointer-events: none;
    transition: all .4s;
  }
  
  .Navbar-to-top.active {
    bottom:32px;
    pointer-events: auto;
    opacity:1;
  }

  .Navbar-to-top-text {
    font-size: .5em;
  }

  @media(max-width: 576px) { 
    .Navbar-logo-some {
        font-size: 2em;
        color: #FFDD30;
        font-weight: bold;
    }
    
    .Navbar-logo-coder {
        font-size: 2em;
        color: #FFFFFF;
        font-weight: bold;
    }
 }

  @media(min-width: 576px) { 
    .Navbar-logo-some {
        font-size: 3em;
        color: #FFDD30;
        font-weight: bold;
    }
    
    .Navbar-logo-coder {
        font-size: 3em;
        color: #FFFFFF;
        font-weight: bold;
    }
 }
  
  @media(min-width: 768px) { 
    .Navbar-logo-some {
        font-size: 3em;
        color: #FFDD30;
        font-weight: bold;
    }
    
    .Navbar-logo-coder {
        font-size: 3em;
        color: #FFFFFF;
        font-weight: bold;
    }
 }

  @media(min-width: 992px) { 
    .Navbar-logo-some {
        font-size: 5vw;
        color: #FFDD30;
        font-weight: bold;
    }
    
    .Navbar-logo-coder {
        font-size: 5vw;
        color: #FFFFFF;
        font-weight: bold;
    }

    .Navbar-links {
        display: inline;
        width: 5em;
        font-size: 14px;
		
    }
    .Navbar-hamburger-toggler {
        display: none;
    }
 }