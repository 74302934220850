@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@700;900&display=swap');
@import  url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  